<template>
  <section id="main">
    <div id="intro">
      <p>
        Hey, {{ studentInfo.name }}
      </p>
      <p>
        {{ dateToday }}
      </p>
    </div>
    <div id="basic">
      <div>
        <p class="textSubTitle ma-0 white--text">
          {{ studentInfo.admNo }}
        </p>
        <p class="textBold textSmall ma-0 white--text">
          Admission Number
        </p>
      </div>
      <div>
        <p class="textSubTitle ma-0 white--text">
          CL. {{ studentInfo.class }}
        </p>
        <p class="textBold textSmall ma-0 white--text">
          Current Class
        </p>
      </div>
      <div class="hideOnMobile">
        <p class="textSubTitle ma-0 white--text">
          {{ studentInfo.admStatus }}
        </p>
        <p class="textBold textSmall ma-0 white--text">
          Enrolment Status
        </p>
      </div>
    </div>
    <div id="options">
      <VCard
        :ripple="{ center: true }"
        @click="goTo('notification')"
      >
        <div class="img">
          <img
            draggable="false"
            :src="require('../assets/images/icon/notification.svg')"
            alt="icon"
          >
        </div>
        <p>Notification</p>
      </VCard>
      <VCard
        :ripple="{ center: true }"
        @click="goTo('timetable')"
      >
        <div class="img">
          <img
            draggable="false"
            :src="require('../assets/images/icon/timetable.svg')"
            alt="icon"
          >
        </div>
        <p>Timetable</p>
      </VCard>
      <VCard
        :ripple="{ center: true }"
        @click="goTo('attendance')"
      >
        <div class="img">
          <img
            draggable="false"
            :src="require('../assets/images/icon/attendance.svg')"
            alt="icon"
          >
        </div>
        <p>Attendance</p>
      </VCard>
      <VCard
        :ripple="{ center: true }"
        @click="goTo('contact')"
      >
        <div class="img">
          <img
            draggable="false"
            :src="require('../assets/images/icon/contact.svg')"
            alt="icon"
          >
        </div>
        <p>Contact School</p>
      </VCard>
      <VCard
        :ripple="{ center: true }"
        @click="goTo('receipt')"
      >
        <div class="img">
          <img
            draggable="false"
            :src="require('../assets/images/icon/receipt.svg')"
            alt="icon"
          >
        </div>
        <p>My Receipt</p>
      </VCard>
      <VCard
        :ripple="{ center: true }"
        @click="goTo('result')"
      >
        <div class="img">
          <img
            draggable="false"
            :src="require('../assets/images/icon/result.svg')"
            alt="icon"
          >
        </div>
        <p>My Result</p>
      </VCard>
    </div>
  </section>
</template>

<script>
import moment from 'moment';

export default {
  data: () => ({
    studentInfo: '',
    dateToday: moment().format('ddd DD MMMM YYYY'),
  }),
  beforeMount() {
    if (this.$store.state.studentInfo === null || undefined) {
      this.$router.push('/logout');
    } else {
      this.studentInfo = this.$store.state.studentInfo;
    }
  },

  methods: {
    goTo(route) {
      this.$router.push(`/${route}`);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../assets/stylus/text';
@import '../assets/stylus/dashboard';
</style>
